<template>
  <v-dialog v-model="show" max-width="650" persistent>
    <v-card v-if="show">
      <v-row no-gutters>
        <v-card-title>Håndtere {{ group.name }}</v-card-title>
        <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
          <v-icon dark>mdi-close-thick</v-icon>
        </v-btn>
      </v-row>
      <v-text-field outlined hide-details dense v-model="search" label="Søg" class="ma-4 white" />
      <v-form ref="ManageGroupsRef">
        <v-card-text>
          <v-data-table
            id="virtual-scroll-table"
            dense
            :search="search"
            :headers="headers"
            :items="accountContacts"
            item-key="id"
            disable-pagination
            hide-default-footer
            show-select
            v-model="contactsInGroup"
          >
            <template #[`item.emailAddresses`]="{ item }">
              {{ $util.FirstEmailInArr(item.emailAddresses) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-block d-md-flex">
          <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="show = false" class="my-4">Annuller</v-btn>
          <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="save" color="success" class="ml-0 ml-md-4 my-4" :loading="isSaving">
            <v-icon left>mdi-content-save</v-icon> Gem Ændringer
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import graphService from '@/services/graphService';
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['azureAccount', 'accountContacts']),
    contactsInGroup: {
      get() {
        return this.accountContacts.filter((x) => this.group.members.some((y) => y.id == x.id));
      },
      set(val) {
        this.group.members = val.map((x) => ({
          Name: x.displayName,
          GivenName: x.givenName,
          id: x.id,
          Email: x.emailAddresses[0].address,
        }));
      },
    },
  },
  data: () => ({
    show: false,
    search: null,
    group: null,
    isSaving: false,
    orignalContacts: [],
    headers: [
      {
        text: 'Fornavn',
        value: 'givenName',
      },
      {
        text: 'Efternavn',
        value: 'surname',
      },
      {
        text: 'Email',
        value: 'emailAddresses',
      },
    ],
  }),
  methods: {
    manageGroup(data) {
      this.group = _.cloneDeep(data);
      this.orignalContacts = _.cloneDeep(this.contactsInGroup);
      this.show = true;
    },
    async save() {
      this.isSaving = true;
      const removedContacts = this.orignalContacts.filter((x) => !this.contactsInGroup.some((y) => y.id === x.id));
      const AddedContacts = this.contactsInGroup.filter((x) => !this.orignalContacts.some((y) => y.id === x.id));

      if (removedContacts.length > 0) {
        for (let rContact of removedContacts) {
          let userGroups = rContact.personalNotes.split(',');
          if (userGroups[userGroups.length] == this.group.name || userGroups.length == 1) {
            const newRContact = rContact.personalNotes.replace(this.group.name, '');
            rContact.personalNotes = newRContact;
            await graphService.editContact(this.azureAccount, rContact.id, rContact);
          } else {
            const newRContact = rContact.personalNotes.replace(',' + this.group.name, '');
            rContact.personalNotes = newRContact;
            await graphService.editContact(this.azureAccount, rContact.id, rContact);
          }
        }
      }
      if (AddedContacts.length > 0) {
        for (let aContacts of AddedContacts) {
          console.log(aContacts);
          let userGroups = aContacts.personalNotes.split(',');
          if (userGroups.length == 0) {
            aContacts.personalNotes = this.group.name;
            await graphService.editContact(this.azureAccount, aContacts.id, aContacts);
          } else {
            let newPerosnalNotes = aContacts.personalNotes.concat(',' + this.group.name);
            aContacts.personalNotes = newPerosnalNotes;
            await graphService.editContact(this.azureAccount, aContacts.id, aContacts);
          }
        }
      }
      this.isSaving = false;
      this.$emit('groups-updated');
      this.show = false;
    },
  },
};
</script>
<style>
#virtual-scroll-table {
  max-height: 400px;
  overflow: auto;
}
</style>