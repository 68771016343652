<template>
  <v-card class="mr-4 pa-0">
    <v-row no-gutters class="text-center" justify="center" align="center">
      <v-col cols="12" class="mt-4">
        <h3>{{ group.name }}</h3>
      </v-col>
      <v-col cols="12" class="pa-0 mt-4">
        <v-row no-gutters class="d-flex grey lighten-2 pa-2" align="center">
          <v-col cols="4" align="left">Medlemmer:</v-col>
          <v-col cols="8" align="right">
            <v-btn depressed large @click="showMangeGroups">
              <v-icon left>mdi-account-plus-outline</v-icon>
              Administer Gruppe
            </v-btn>
          </v-col>
          <v-col cols="4" class="mt-4" align="left">Genere mail:</v-col>
          <v-col cols="8" class="mt-4" align="right">
            <v-btn depressed large @click="OpenMail">
              <v-icon left>mdi-email-fast-outline</v-icon>
              Send mail
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-row no-gutters align="start" v-for="user in filterContact" :key="user.id" class="pt-2" style="text-align: left">
        <v-col cols="12" class="bt-2">
          <p class="pl-2 mb-0">{{ user.Name }}</p>
        </v-col>
        <v-col cols="12" class="pb-0">
          <p class="pl-2">{{ user.Email }}</p>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-row>
    <manage-groups ref="ManageGroupsRef" @groups-updated="bubbleEmit" :thisgroup="group" />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageGroups from '@/components/Dialogs/ManageGroups.vue';
export default {
  props: {
    group: Object,
    search: String,
  },
  components: {
    ManageGroups,
  },
  data: () => ({
    users: null,
    names: 'mailto:',
  }),
  computed: {
    ...mapGetters(['azureAccount']),
    filterContact() {
      if (this.search) {
        const search = this.search.toLowerCase();
        return this.group.members.filter(
          (item) => item.Name?.toLowerCase().includes(search) || item.GivenName?.toLowerCase().includes(search) || item.Email?.toLowerCase().includes(search)
        );
      } else {
        return this.group.members;
      }
    },
  },
  methods: {
    showMangeGroups() {
      this.$refs.ManageGroupsRef.manageGroup(this.group);
    },
    bubbleEmit() {
      this.$emit('groups-updated');
    },
    OpenMail() {
      this.group.members.forEach((member) => {
        if (this.names == 'mailto:') {
          this.names = this.names + member.Email;
        } else {
          this.names = this.names + '; ' + member.Email;
        }
      });
      location.href = this.names;
      this.names = 'mailto:';
    },
  },
};
</script>