<template>
  <v-container>
    <v-row no-gutters align="center">
      <v-col cols="4">
        <h2>Grupper</h2>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined hide-details dense v-model="search" label="Søg" class="ma-4 white" />
      </v-col>
      <v-col cols="4" align="right"> </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="" v-for="group in searchAccountGroups" :key="group.id" cols="12" md="4" xl="3">
        <RContactBox :search="search" @groups-updated="bubbleEmit" :group="group" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import RContactBox from '@/components/RContactBox.vue';
export default {
  components: {
    RContactBox,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapGetters(['accountGroups']),
    searchAccountGroups() {
      if (this.search) {
        const search = this.search.toLowerCase();
        return this.accountGroups.filter((group) =>
          group.members.some(
            (item) => item.Name?.toLowerCase().includes(search) || item.GivenName?.toLowerCase().includes(search) || item.Email?.toLowerCase().includes(search)
          )
        );
      } else {
        return this.accountGroups;
      }
    },
  },
  methods: {
    bubbleEmit() {
      this.$store.dispatch('LOAD_GROUPS_AND_CONTACTS', this.azureAccount);
    },
  },
  created() {},
};
</script>

<style>
</style>